import {Message, Loading} from 'element-ui'

export function MessageSuccess(val) {
  Message({
    message: val,
    type: 'success',
    center: true,
    showClose: true,
  })
}
export function MessageError(val) {
  Message({
    message: val,
    type: 'error',
    center: true,
    showClose: true,
  })
}

export function MessageWarning(val) {
  Message({
    message: val,
    type: 'warning',
    center: true,
    showClose: true,
  })
}

export function MessageInfo(val) {
  Message({
    message: val,
    type: 'info',
    center: true,
    showClose: true,
  })
}

export function OpenLoading() {
  let loadingInstance = Loading.service({
    lock: true,
    text: '正在加载...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  setTimeout(() => {
    loadingInstance.close()
  }, 2000)
  return loadingInstance
}

export function startLoading() {
  let loadingInstance = Loading.service({
    lock: true,
    text: '正在加载...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  setTimeout(() => {
    loadingInstance.close()
  }, 20000)
  return loadingInstance
}

export function randomCoding(){
  //创建26个字母数组
  let arr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','0','1','2','3','4','5','6','7','8','9'];
  let idvalue ='';
  let n = 10;//这个值可以改变的，对应的生成多少个字母，根据自己需求所改
  for(let i=0;i<n;i++){
    idvalue+=arr[Math.floor(Math.random()*35)];
  }
  return idvalue
}

export function randomNum(){
  let arr = ['1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  let idvalue ='';
  let n = 6;
  for(let i=0;i<n;i++){
    idvalue+=arr[Math.floor(Math.random()*30)];
  }
  return idvalue
}

export function formatObj(obj) {
  return JSON.parse(JSON.stringify(obj))
}



